<template>
    <div class="inner-container" :class="{ 'min-container': !getTabs }">
      <stepperGeneric  :steps="steps" @complete="handleCompletion"></stepperGeneric>
    </div>
  </template>
  
  <script>
  import stepperGeneric from '../../components/stepper/stepperGeneric.vue';
  import stepAdresseBar from '../../components/stepper/stepAdresseBar.vue';
  import stepChaniterBarTH137 from '../../components/stepper/devisTH137/stepChaniterBarTH137.vue';
  import stepClientBar from '../../components/stepper/stepClientBar.vue';
  import stepGeneraliteBar from '../../components/stepper/stepGeneraliteBar.vue';
  import stepInformationBarTH137 from '../../components/stepper/devisTH137/stepInformationBarTH137.vue';
  import stepInstallateurBar from '../../components/stepper/stepInstallateurBar.vue';
  import stepReglementBar from '../../components/stepper/stepReglementBar.vue';
  import stepDevisBar from '../../components/stepper/stepDevisBar.vue';
  
  import { mapGetters } from "vuex";
  
  export default {
    components: {
      stepperGeneric,
    
    },
    data() {
      return {
        operation:false,
        steps: [
          { label: this.$t("Généralité"), component: stepGeneraliteBar },
          { label: this.$t("CLIENT"), component: stepClientBar },
          { label: this.$t("INSTALLATEUR"), component: stepInstallateurBar },
          { label: this.$t("Adresse"), component: stepAdresseBar },
          { label: this.$t("Information"), component: stepInformationBarTH137 },
          { label: this.$t("Chantier"), component: stepChaniterBarTH137 },
          { label: this.$t("Règlement"), component: stepReglementBar },
          { label: this.$t("Devis"), component: stepDevisBar },
        ],
      };
    },
    computed: {
      ...mapGetters(["getTabs"]),
    },
    methods: {
      handleCompletion() {
        this.$router.push('/devis/list');
      },
    },
  };
  </script>
    <style scoped>
    .inner-container .content {
      margin: 10px 15px;
      background-color: #fff;
      box-shadow: 1px 1px 24px #00000019;
      border-radius: 5px;
      padding: 14px;
      min-height: 88vh;
      margin-top: 55px;
    }
    
    </style>